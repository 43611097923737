@import "./variables";

@mixin selectTag {
    select {
        height: 2.4rem;
        border-radius: 0.5rem;
        border: 1px solid #000;
        padding: 5px 0;
        padding-left: 14px;
        font-size: 1rem;

        @media screen and (max-width: $sm) {
            font-size: 0.8rem;
        }

        &.multiple {
            height: 5rem !important;
            @media screen and (max-width: 800px) {
                height: 4rem !important;
            }
            @media screen and (max-width: 500px) {
                height: 3rem !important;
            }
        }

        option {
            font-size: 0.85rem;
            font-weight: 400;
            @media screen and (max-width: 800px) {
                font-size: 0.6rem !important;
            }
        }

        @media screen and (max-width: 800px) {
            font-size: 14px !important;
            height: 1.9rem;
        }

        @media screen and (max-width: 500px) {
            font-size: 10px !important;
            height: 1.7rem;
        }
    }
}

.delete-btn {
    background-color: #1b75bc !important;
}

.download-icon {
    margin: 0rem 0.3rem;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

.year-text {
    font-size: 0.8rem;
    margin-right: 2px;
}

.download-text {
    font-size: 0.8rem;
    margin-right: 2px;
    font-weight: 500;
    color: #000;
    max-width: 8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.AddEquipmentIcon {
    height: 19px;
    width: 19px;
}

.common-textfield {
    border-radius: 0.5rem !important;
}

.close-icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    margin-bottom: 0.5rem;

    &:hover {
        opacity: 0.7;
        border-radius: 50%;
        background-color: #d6d6d6;
    }
}
.divider {
    margin-bottom: 1rem;
    border: 0.5px solid rgb(207, 207, 207);
    width: 100%;
}

.btn-primary {
    background-color: #1b75bc !important;
}

.divider {
    margin-bottom: 1rem;
    border: 0.5px solid rgb(207, 207, 207);
    width: 100%;
}

.otp-text {
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    color: #000;
    margin-bottom: 1rem;
    text-align: center;
}

.otp-input {
    input {
        width: 2em !important;
        height: 2em !important;
    }
}

.otp-submit__btn {
    margin-top: 1rem;
    width: 100%;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 0;
    text-transform: capitalize;
    border: 1px solid #000;
    background-color: #1b75bc;
}

.otp-submit__btn:hover {
    background-color: #1b75bc;
    color: #000;
}

.collapsed-box {
    box-shadow: none;
    padding: 0.5rem 1rem;
    border: 1px solid #303030;
    width: 100%;
    margin-bottom: 1rem !important;
    border-radius: 0.5rem !important;
    .status-heading {
        font-size: 1rem;
        font-weight: 600;
        color: #000;
    }
    .status-text {
        font-size: 1rem;
        font-weight: 500;
        color: #000;
    }
}

.common-custom-label {
    position: relative;
    @media screen and (max-width: 800px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 500px) {
        font-size: 10px !important;
    }
}

.common-accordion-field,
.common-non-mui-accordian {
    box-shadow: none;
    border: 1px solid #303030;
    width: 100%;
    margin-bottom: 1rem !important;
    border-radius: 0.5rem !important;

    &.error {
        border: 1.5px solid red !important;
    }

    .MuiAccordionSummary-root {
        min-height: 1rem;
    }

    .MuiAccordionSummary-content {
        margin: 5px 0;

        > div {
            margin: 0 !important;
        }
    }
}

.common-non-mui-accordian > div {
    margin: 0 !important;
    padding: 0 1rem;
}

.other-label {
    font-size: 0.9rem;
    width: 95%;
    text-align: center;
    border-radius: 0.5rem;
    border: 1px solid #000;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    padding: 0.5rem 1rem;
    background-color: #e9ecef;
    font-family: "Segoe UI";
    letter-spacing: 0.04rem;
    margin-bottom: 0.5rem;
}

// .MuiOutlinedInput-input {
//     padding: 0.5rem 1rem;
// }
@media screen and (max-width: $sm) {
    .long__accordian {
        width: 72vw !important;
    }
}

.common__mui__checkbox__container {
    border: 1px solid black;
    padding: 10px;

    label {
        color: #7c7c7c;

        @media screen and (max-width: $sm) {
            height: auto;
        }
        .MuiTypography-root {
            font-size: 0.9rem;
        }
    }
}

.common__or__container {
    $line-border: 2px solid rgb(133 133 133);
    $line-width: 1rem;
    $circle-width: 2rem;
    $subtitle-height: 12px;
    $error-height: 6rem;

    margin-top: 1.4rem;

    .or__top {
        position: relative;
        padding-left: calc($line-width + $circle-width);

        &--has-subtitle {
            top: calc(-1 * $subtitle-height);
        }

        &--has-error {
            margin-top: 1rem;
        }

        .line {
            position: absolute;
            top: 50%;
            left: 1rem;
            height: calc(100% + $circle-width);
            width: $line-width;
            border-left: $line-border;
            z-index: 1;
            border-bottom: none;
            border-top: $line-border;

            &--has-error {
                height: calc(100% + $error-height);
            }
        }
    }

    .or__bottom {
        position: relative;
        padding-left: calc($line-width + $circle-width);

        &--has-subtitle {
            top: calc(-1 * $subtitle-height);
        }

        &--has-error {
            margin-top: 1rem;
        }

        .line {
            position: absolute;
            bottom: 50%;
            left: 1rem;
            height: calc(100% + $circle-width);
            width: $line-width;
            border-left: $line-border;
            z-index: 1;
            border-top: none;
            border-bottom: $line-border;
        }
    }

    .or__circle {
        &--has-subtitle {
            position: relative;
            top: calc(-1 * $subtitle-height);
        }

        width: $circle-width;
        height: $circle-width;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #e0e0e0;
        font: 400 9px/10px "Open Sans", sans-serif;
        text-transform: uppercase;
        background-color: #fff;
        z-index: 2;
        position: relative;
    }
}

.MuiFormLabel-root.Mui-focused {
    color: rgb(0 0 0) !important;
}

.MuiOutlinedInput-root,
.MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
        border-color: rgb(0 0 0) !important;
    }
}

#custom-accordion {
    &.MuiAccordionSummary-root {
        min-height: 0;
        height: 2.5rem;
    }
}

.common-fullwidth-button {
    border: 1px solid #000;
    border-radius: 0.5rem;
    width: 100%;
    padding: 5px 0;
    max-height: 2.2rem;
    padding-left: 14px;
    color: black;
    justify-content: flex-start;
    text-transform: initial;

    @media screen and (max-width: 800px) {
        font-size: 14px !important;

        svg {
            width: 1rem;
        }
    }

    @media screen and (max-width: 500px) {
        font-size: 10px !important;
        height: 1.7rem;

        svg {
            width: 1rem;
        }
    }
}

.common-fullwidth-button span {
    margin-left: 10px;
}

.common-fullwidth-select {
    width: 100%;
    height: 2.4rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;

    & :focus {
        outline: none;
        border: 1px solid red;
    }

    font-size: 1rem !important;

    @media screen and (max-width: 800px) {
        font-size: 14px !important;
        height: 1.7rem;
    }

    @media screen and (max-width: 500px) {
        font-size: 10px !important;
    }
}

.common-mui-accordian__text {
    font-size: 1rem !important;

    @media screen and (max-width: 800px) {
        font-size: 14px !important;
    }

    @media screen and (max-width: 500px) {
        font-size: 11px !important;
    }
}

.MuiList-root {
    // height: 2.7rem;
    border: 1px solid rgb(34 34 34);
    padding: 0;
    font-size: 0.8rem;
    opacity: 1;
    width: 100% !important;

    .MuiButtonBase-root {
        margin-top: -0.1rem;
        padding: 0.7rem auto;
        height: 2rem;
        font-size: 1rem;
        font-weight: 400;

        &.Mui-selected {
            color: white;
            width: 100%;
            background-color: #3298fd !important;
        }

        &:hover {
            color: white;
            background-color: #3298fd !important;
        }
    }
}

.common-select-menu-item {
    margin: 0;
    width: 100%;
    background-color: white;
    padding: 0 10px;
}

.short-input-field {
    width: 5rem;
}

// .MuiAccordion-root{
//     margin:.5rem;
// }

.white {
    color: white;
}

.common__mui__textfield__container {
    ::-webkit-calendar-picker-indicator {
        transform: scale(1.45);
    }

    margin-top: 1.4rem;
    margin-bottom: 0 !important;

    &:nth-of-type(1) {
        margin-top: 1rem;
    }
}

.common__or__container.invoice_container #margin_bottom_zero {
    margin-bottom: 0;
}

.common-label {
    // padding-top: 10px;

    @media screen and (max-width: $sm) {
        // font-size: 0.7rem !important;
        width: 100%;
    }
}

.common-mui-accordian__radio {
    .MuiRadio-root {
        padding: 0 9px;
    }

    @media screen and (max-width: $sm) {
        // span {
        //     font-size: 0.6rem;
        // }
    }
}

.common-mui-accordian__radio--without-infotext {
    .MuiRadio-root {
        padding: 9px;
    }

    @media screen and (max-width: $sm) {
        span {
            font-size: 0.6rem;
        }
    }
}

.common__mui__selectfield {
    @include selectTag;

    &.error {
        select {
            border-color: red !important;
        }
    }

    // height: 2.4rem;
    // border-radius: 0.5rem;
    // border: 1px solid #000;
    // padding: 5px 0;
    // padding-left: 14px;
    // font-size: 1rem;
    // div {
    //     padding: 0.3rem 0.6rem;
    //     font-size: 0.9rem;
    //     border-radius: 16px;

    // .MuiMenu-list {
    //     padding: 0;
    // }

    // .MuiMenuItem-gutters {
    //     font-size: 0.6rem;
    //     padding: 0;
    //     margin: 0;
    // }
    // }
    // }
}

.common__helper-text {
    font-style: italic;
    color: black;
    letter-spacing: 0;

    @media screen and (max-width: $sm) {
        font-size: 0.6rem;
        overflow-y: hidden;
        margin-bottom: 0.6rem;
    }

    margin-left: 0;

    &.absolute {
        position: absolute;
        bottom: -1rem;
        height: 1rem;

        @media screen and (max-width: $sm) {
            bottom: -1.7rem;
        }
    }
}

.asset__container,
.loan__container {
    position: relative;
}

.validate {
    border: 1px solid;
    color: #000;
    background-color: #ece9ef;
    padding: 5px;
    width: fit-content;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 0.6rem;
    font-weight: 100;
    cursor: pointer;

    &.verified {
        color: green;
    }

    // .icon {
    //     color: green;
    // }

    @media screen and (max-width: $md) {
        font-size: 0.6rem;

        .icon {
            font-size: 0.8rem;
        }
    }

    &.absolute {
        position: absolute;
        bottom: -2rem;
        height: 1rem;
    }

    span {
        padding-bottom: 0;
    }
    &.btn {
        padding: 0.3rem 0.6rem;
        margin-top: 2rem;
        text-transform: capitalize;
        .icon {
            margin-right: 3px;
        }
    }
}

.common__mui__textfield-grey {
    background-color: #e9ecef !important;
}

.form-title-highlight {
    background-color: #e9ecef;
    opacity: 1;
    width: 96%;
    margin: auto;
    text-align: center;
    font-size: 0.9rem;
    border-radius: 0.5rem;
    border: 1px solid #000;
    height: 2.4rem;
    padding: 0.2rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.03rem;
    color: #495057;
}

.form-highlight-heading {
    border: 1px solid #000;
    background-color: #fff;
    color: #1b75bc !important;
    display: block;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-bottom: -1px;
}

.common__mui__textfield {
    position: relative;

    &.bg-shade {
        .MuiInputBase-root {
            background-color: #e9ecef;
            opacity: 1;
        }
    }

    &.error {
        .common-textfield .MuiInputAdornment-positionStart {
            border-right: 2px solid red;
        }

        .common-textfield .MuiOutlinedInput-root.Mui-focused {
            border-color: red !important;
        }

        .common-textfield .MuiOutlinedInput-notchedOutline {
            border-color: red !important;
            border: 2px solid;
        }
    }

    &.border-red {
        .common-textfield .MuiInputAdornment-positionEnd {
            border-left: 2px solid red;
        }

        .common-textfield .MuiFormLabel-root.Mui-focused {
            color: red;
        }

        .common-textfield .MuiOutlinedInput-root.Mui-focused {
            border-color: red !important;
        }

        .common-textfield .MuiOutlinedInput-notchedOutline {
            border-color: red !important;
            border: 2px solid;
        }

        .common-textfield {
            padding-right: 0.8rem;

            // border: 2px solid red;
        }

        input {
            color: red;
        }
    }

    &.border-none {
        .MuiInputBase-root {
            border: none;
            padding: 0;

            input {
                padding: 0;
            }
        }
    }

    .autocomplete-input > div {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        input {
            font-size: 1rem;
        }
    }

    .common-textfield {
        padding-left: 0;
        padding-right: 0;
        border: none;
        font-size: 0.9rem;
        min-height: 2.35rem;
        padding: 0 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;

        @media screen and (max-width: 600px) {
            width: auto;
        }

        @media screen and (max-width: 800px) {
            font-size: 14px !important;
            height: 1.9rem;
        }

        @media screen and (max-width: 500px) {
            font-size: 10px !important;
            height: 1.7rem;
        }

        .MuiInputAdornment-positionEnd,
        .MuiInputAdornment-positionStart {
            height: 150%;
            padding: 0.4rem 0.75rem;
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            text-align: center;
            white-space: nowrap;
            background-color: #e9ecef;
        }

        .MuiInputAdornment-positionEnd {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-left: 1px solid black;
            margin-right: -0.8rem;
        }

        .MuiInputAdornment-positionStart {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-right: 1px solid black;
            margin-left: -0.8rem;
        }

        input {
            padding: 0;
            font-size: 0.9rem;
            padding-left: 0;

            &::placeholder {
                font-size: 0.8rem;
            }
        }
    }
}

.MuiFormControlLabel-label {
    @media screen and (max-width: 800px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 500px) {
        font-size: 10px !important;
    }
}

.common-accordian__title {
    font-size: 1rem;
    font-weight: 400;
    @media screen and (max-width: 800px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 500px) {
        font-size: 10px !important;
    }
}

.MuiTelInput-IconButton {
    img {
        width: 1rem;
        height: 1rem;
    }

    padding: 0;
    margin: 0;
}

.common__mui__date__container {
    width: 80%;

    &.read-only {
        background-color: #e9ecef !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
    }

    input {
        padding: 10px;
    }

    label {
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
    }

    .Mui-error {
        color: dimgrey;
    }

    .MuiFormLabel-filled {
        display: none;
    }
}

.common__mui__date__container:focus-within {
    label {
        display: none;
    }
}
.MuiRadio-root {
    .MuiSvgIcon-root {
        height: 0.9em;
        width: 0.9em;
        @media screen and (max-width: 800px) {
            height: 0.75em;
            width: 0.75em;
        }
    }
}

select {
    height: 2.4rem;
    font-size: 0.9rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;

    @media screen and (max-width: 600px) {
        width: auto;
    }
}

.Mui-error {
    margin: 0;
}
.not-expandable-accordion {
    .Mui-expanded {
        transform: none;
    }
}
.react-tel-input {
    .form-control {
        width: 100%;
        border: 1px solid #000;
        border-radius: 0.5rem !important;
    }
    .flag-dropdown,
    .flag-dropdown:hover {
        border: 1px solid #000;
        border-radius: 0.5rem 0 0rem 0.5rem !important;
    }
    .selected-flag {
        background: transparent !important;
    }
}
