@import "styles/variables";

#equipment__container {
    width: 100%;
    margin: 0 auto;

    .field {
        margin-bottom: $margin-between-fields;
    }

    .subtitle {
        margin-top: 0.3rem;
        font-size: 0.6rem;
        font-style: italic;
    }

    .own-margin-or-loan-to-value-container {
        background-color: #aaf9f8;
        border-radius: 0.6rem;
        padding: 10%;
        padding-bottom: 5%;
    }
}
