.contact-us-section-3 {
    width: 67vw;
    margin: auto;
    margin-top: 1.25rem;
    margin-bottom: 1.875rem;
    border-radius: 0.31rem;
    background-color: #ccc;
    padding: 1.2rem;

    @media screen and (max-width: 800px) {
        width: 90vw;
    }
    @media screen and (max-width: 500px) {
        width: 95vw;
    }

    .left {
        @media screen and (max-width: 500px) {
            text-align: center;
        }

        .left-h5 {
            font-weight: bold;
            font-style: italic;
            font-size: 2.5rem;
            margin: 0.625rem 0;
            font-family: Arial;
            transform: scaleY(0.9);
            @media screen and (max-width: 800px) {
                font-size: 1.5rem;
            }
            @media screen and (max-width: 500px) {
                font-size: 0.9rem;
            }
        }

        .left-p {
            font-size: 0.875rem;
            font-weight: normal;
            font-style: normal;
            padding-left: 0.25rem;
            padding-right: 0.625rem;
        }
    }

    .right {
        padding-right: 0.94rem;

        .row1 {
            margin-top: 1.1rem;
            width: 100%;
            @media screen and (max-width: 500px) {
                .row1-textfield {
                    width: 100% !important;
                    margin-top: 0.6rem;
                }
            }

            .input-text {
                margin: 0 !important;
                background-color: white;
                font-size: 0.94rem !important;
                padding: 0.625rem !important;
                height: 1.875rem !important;
            }
        }

        .input-text-field {
            margin: 0 !important;
            font-size: 0.94rem !important;
            padding: 0.625rem !important;
            background-color: white;
            height: 1.8rem !important;
            @media screen and (max-width: 800px) {
                font-size: 0.6rem;
                padding: 0.3rem;
                height: 0.9rem;
            }
            @media screen and (max-width: 500px) {
                height: 0.6rem;
            }
        }

        .button {
            width: 100%;
            padding: 0.75rem;
            margin: 0.625rem 0;
            font-size: 1rem;
            @media screen and (max-width: 800px) {
                padding: 0.3rem;
                font-size: 0.6rem;
            }
            @media screen and (max-width: 500px) {
                font-size: 0.3rem;
            }
        }

        #contact-us-section-3-p {
            margin-left: 0.31rem;
            letter-spacing: 0.06rem;
            font-size: 0.75rem !important;

            a {
                color: black;
                font-size: 0.75rem !important;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    @media screen and (max-width: 800px) {
        .contact-text {
            top: 20%;
        }

        .contact-us-section-3 {
            width: 90vw;

            .right {
                padding-right: 0;

                .row1 {
                    .input-text {
                        padding: 0.31rem;
                        height: 0.94rem;
                    }
                }

                .input-text-field {
                    padding: 0.31rem;
                    height: 0.94rem;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #contact-us-container {
        .contact-us-section-3 {
            width: 95vw;

            .left {
                text-align: center;
            }

            .right {
                padding-right: 0;

                .row1 {
                    .input-text {
                        font-size: 0.625rem;
                        padding: 0.31rem;
                        height: 0.625rem;
                    }

                    .row1-textfield {
                        width: 100% !important;
                        margin-top: 0.625rem;
                    }
                }

                .input-text-field {
                    font-size: 0.625rem;
                    padding: 0.31rem;
                    height: 0.625rem;
                }
            }
        }
    }
}
