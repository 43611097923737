#machine-usage__container {
    // margin: $margin-between-fields auto;

    .field-text {
        font-size: 1rem !important;
    }

    @media screen and (max-width: 800px) {
        .field-text {
            font-size: 14px !important;
        }
    }

    @media screen and (max-width: 500px) {
        .field-text {
            font-size: 10px !important;
        }
    }

    .expected-machine-usage {
        width: 100%;
        background-color: #e9ecef;
        margin-top: 1rem;
        border-radius: 0.5rem;
        border: 1px solid black;
        font-size: 0.9rem;
        text-align: center;
        padding: 0.3rem;
    }
}
