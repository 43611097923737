@import "styles/variables";

#loan-steps__container {
    .specializations {
        margin: 3rem 0 2rem;
        text-align: center;
        position: relative;

        @media screen and (max-width: $md) {
            margin: 2rem 0 0;
        }

        img {
            &:first-of-type {
                width: 3rem;
                height: 3rem;
                border-radius: 0.5rem;
                padding: 1.5rem;
            }
        }

        h5 {
            cursor: pointer;
            text-transform: uppercase;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            padding-top: 10px;
            font-style: italic;
            font-family: arial;
            margin-right: 1.5rem;
            margin-left: 1.3rem;
            margin-top: -0.6rem;
            letter-spacing: 1px;
        }
    }

    .home-btn {
        button {
            white-space: nowrap;
            background-color: white;
            border-radius: 30px;
            box-shadow: 0 5px 10px 0 gray;
            padding: 0 25px;
            font-weight: bolder;
            color: #f15a29;
            font-size: 2em;
            text-transform: none;
            margin: 20px 30px;
            @media screen and (max-width: 768px) {
                font-size: 1.2em;
            }
        }
    }

    .demo-btn {
        @media screen and (max-width: $md) {
            margin-top: 2rem;
        }

        button {
            padding: 5px 30px;
            background-color: #f15a29;
            font-weight: bold;
            font-size: 1em;
            margin-bottom: 20px;
        }
    }
    @media screen and (min-width: $sm) {
        span {
            display: block;
        }
    }
}
