#loan-form {
    // margin: 1.2rem auto;

    .field-text {
        font-size: 1rem !important;
    }

    @media screen and (max-width: 800px) {
        .field-text {
            font-size: 14px !important;
        }
    }

    @media screen and (max-width: 500px) {
        .field-text {
            font-size: 10px !important;
        }
    }
}
