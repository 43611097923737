@import "styles/variables";

body {
    margin: 0;
    background-color: #d7d7d7;
}

.nav-bar-sticky {
    .nav__content {
        transform: translateY(6px);
    }

    position: sticky;
    top: 0;
    z-index: 1;

    .logo {
        //  display: block;
        padding-top: 5px;
        padding-bottom: 0;
        margin-left: -5px;
        margin-bottom: -4px;
        width: 15rem;
        height: 1.65rem;
        vertical-align: middle;
        margin: 0 0 0 10px;

        @media screen and (min-width: 300px) {
            width: 216px;
        }
    }

    .MuiPaper-root {
        border-bottom: none;
    }

    .MuiAccordionDetails-root {
        margin-top: 1rem;
        padding-top: 3px;
        border: none;

        .MuiList-root {
            border: none !important;
            padding: 0;
            opacity: 1;

            a {
                color: #1b75bc;
                padding-right: 0.9rem !important;

                &:hover {
                    background: transparent !important;
                }

                &.active {
                    background: transparent;
                }

                .MuiTypography-root {
                    line-height: 1.4rem;
                    text-decoration: none;
                    font-size: 1.1rem !important;
                    font-weight: 500;
                }
            }
        }
    }

    .app-nav-links {
        color: #1b75bc !important;
        font-size: 1.2rem !important;
        padding-right: 1.9rem !important;
        margin-bottom: -0.5rem;
        font-weight: 500;
        font-family: zurich-cn-bt;
    }

    .top-section {
        background-color: #1b75bc;
        width: 100%;
        height: 4px;
    }

    .nav-login-btn {
        font-weight: 400;
        font-size: 1.1rem;
        color: #fff !important;
        background-color: #f15a29 !important;
        letter-spacing: 1px;
        padding: 0 4px;
    }

    .nav-icon {
        margin-left: 20px;
        color: rgb(0 0 0 / 50%);
        padding: 5px;
        border: 1px solid rgb(0 0 0 / 10%);
    }
}

/* 
.t1 {
  color: #4c99d4;
}

.btn {
  margin-right: 15px;
}
.c1 {
  position: relative;
  width: 550px;
  height: 650px;
}

.mask {
  position: relative;
  width: 50%;
  height: 40%;
}

.login {
  position: absolute;
  color: orangered;
  left: 200px;
  top: 20px;
}

.text1 {
  position: absolute;
  width: 300px;
  left: 130px;
  top: 170px;
}

.text2 {
  position: absolute;
  width: 300px;
  left: 130px;
  top: 200px;
}

.icon {
  position: absolute;
  color: black;
  top: 322px;
  left: 320px;
}

.otp {
  position: absolute;
  color: #4c99d4;
  top: 322px;
  left: 350px;
}

.forgot {
  position: absolute;
  color: blue;
  top: 368px;
  left: 260px;
}

.btn2 {
  position: absolute;
  left: -120px;
  width: 200px;
  height: 50px;
  top: 350px;
}

.new {
  position: absolute;
  top: 520px;
  left: 190px;
  color: blue;
}

.card {
  position: absolute;
  height: 10%;
  margin-top: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.copyright {
  position: relative;
  top: 40%;
  width: 100%;
  left: 29%;
}

.div {
  position: relative;
  position: fixed;
  margin-left: 48%;
}

.insta {
  position: relative;
  margin-right: 10px;
}
 */
