@import "styles/variables";

$margin-between-fields: 1.2rem;

#dashboard-container {
    padding: 2% 0;
    padding-bottom: 5%;
    width: 100%;
    height: fit-content;
    @media screen and (max-width: $md) {
        > div {
            width: 100%;
        }
    }
    .fieldset {
        border: none;
    }

    @media screen and (max-width: 800px) {
        padding: 0 1rem;
        font-size: 0.8em;
    }

    .table__container {
        width: 100%;
        @media screen and (max-width: $md) {
            width: 100%;
        }

        .table__head {
            position: relative;
            padding: 0;
            @media screen and (max-width: $md) {
                font-size: 0.6rem;
                padding: 0;
            }

            .popuptext {
                visibility: hidden;
                width: 200px;
                background-color: #000;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 8px 5px;
                line-height: 1rem;
                position: absolute;
                z-index: 1;
                bottom: -120%;
                left: 50%;
                margin-left: -100px;
            }

            .popuptext::after {
                content: "";
                position: absolute;
                top: -100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #000 transparent transparent;
            }

            .show {
                visibility: visible;
                animation: fadeIn 1s;
                animation: fadeIn 1s;
            }

            &.MuiTableCell-head {
                // font-size: 1em;
                font-weight: 600;
                color: #212529;
                text-align: center;
                width: 14.28%;
                padding: 0.3rem;
            }
        }

        .MuiTableCell-root {
            .common__mui__textfield .common-textfield {
                // min-height: 0 !important;
                color: #495057 !important;
            }
        }

        .table__data {
            border: 1;
            padding: 0 5px;

            @media screen and (max-width: $md) {
                input {
                    height: 1rem;
                }
            }

            .common-label {
                padding-top: 0 !important;
            }

            .common__mui__textfield__container:nth-of-type(1) {
                margin-top: 0 !important;
            }

            .common__mui__textfield__container {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
        }
    }

    #button-list-container {
        @media screen and (max-width: $sm) {
            padding: 20px 0 0;
        }

        padding: 0;

        .button {
            width: 85px;
            height: 100px;
            border: 1px solid #a5a5a5;
            font-size: 0.4rem;
            color: black;
            letter-spacing: 0;
            font-weight: 400;
            border-radius: 5px 0 0 5px;
            @media screen and (max-width: $md) {
                border-radius: 0.5rem 0.5rem 0 0;
                height: 100px;
            }

            &:nth-of-type(1) {
                background-color: #f9aaaa;
                padding: 0;
                margin: 0;
            }

            &:nth-of-type(2) {
                background-color: #f9e5aa;
            }

            &:nth-of-type(3) {
                background-color: #b9f9aa;
            }

            &:nth-of-type(4) {
                background-color: #aaf9f8;
            }

            &:nth-of-type(5) {
                background-color: #aabdf9;
            }

            &:nth-of-type(6) {
                background-color: #ffb5ec;
            }
            &:nth-of-type(7) {
                background-color: #f9e5aa;
            }
            &:nth-of-type(8) {
                background-color: #b9f9aa;
            }

            &.active {
                //box-shadow: -0.1rem 3px 0.25rem grey;
                box-shadow: -2.5px 2.5px 3.5px #939598;
                border-right: 0;
                width: 87px;
                z-index: 1;
            }

            span {
                font-size: 10px;

                @media screen and (max-width: $md) {
                    font-size: 0.6rem;
                }
            }

            .button-img {
                height: 49px;
                width: 49px;

                @media screen and (max-width: 800px) {
                    width: 40px;
                    height: 40px;
                }

                @media screen and (max-width: 500px) {
                    width: 30px;
                }
            }

            @media screen and (max-width: $md) {
                padding: 0.3rem 0 !important;
            }
        }

        .mobile-btn {
            .button {
                .button-img {
                    width: 40px;
                }
                width: 100%;
                @media screen and (max-width: 900px) {
                    width: 5rem;
                }
            }

            &:nth-of-type(1) .button {
                background-color: #f9aaaa;
            }

            &:nth-of-type(2) .button {
                background-color: #f9e5aa;
            }

            &:nth-of-type(3) .button {
                background-color: #b9f9aa;
            }

            &:nth-of-type(4) .button {
                background-color: #aaf9f8;
            }

            &:nth-of-type(5) .button {
                background-color: #aabdf9;
            }

            &:nth-of-type(6) .button {
                background-color: #ffb5ec;
            }

            & .button.active {
                box-shadow: 0.2rem 0 0.25rem grey;
            }

            &:nth-of-type(5) .button {
                background-color: #aabdf9;
            }
        }
        @media screen and (max-width: 900px) {
            justify-content: start;
            margin-left: 1rem;
        }
    }

    #form-container {
        position: relative;
        border-radius: 0 10px 10px 0;
        box-shadow: rgb(35 31 32 / 30%) 0 1px 10px;
        max-width: 80%;
        min-width: 76%;
        min-height: 50vh;

        @media screen and (max-width: $md) {
            max-width: 95%;
        }

        #income-form {
            margin-top: 1.5rem;
            @media screen and (max-width: $sm) {
                margin-left: 0;
            }
        }

        #personal-form {
            margin-top: 3.1rem;
            margin-left: 1.5rem;
        }

        @media screen and (min-width: $md) {
            font-size: 0.5rem;
        }
    }

    .table__container {
        position: absolute;
        left: 8px;
        right: 0;
        max-width: 97%;
        margin-top: 15px;

        @media screen and (max-width: $md) {
            width: 100%;
        }

        .table__head {
            @media screen and (max-width: $md) {
                font-size: 0.6rem;
                padding: 0;
            }
        }

        .table__data {
            border: 1;
            padding: 0 5px;
            @media screen and (max-width: $md) {
                input {
                    height: 1rem;
                }
            }
        }
    }

    .personal-header {
        border: 1px solid black;
        background: #e9ecef;
        border-radius: 0.5rem;
        height: fit-content;

        h4 {
            margin: 0.5rem 0;
            color: #495057;
            font-size: 0.9rem;
        }
    }

    .tab-panel {
        max-width: 600px;
        margin-left: 2rem;
    }
}

body {
    background-color: white !important;
}
