#small__card {
    padding: 20px 10px;
    width: 80%;
    background-color: #e9ecef;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid black;
    min-height: 150px;
    height: 100%;
    @media screen and (max-width: 800px) {
        width: 100%;
        height: 100%;
    }

    .details {  
        h5 {
            padding-left: 1rem;

            span {
                color: #1b75bc;
            }

            padding-bottom: 5px;
        }
    }

    #btn {
        width: 100%;
        font-size: 1rem;
        margin-left: auto;
        @media screen and (max-width: 500px) {
            font-size: 0.5rem;
        }
    }

    .progress {
        margin: 0.6rem 0;
        margin-left: 6px;
        width: 95%;
        height: 1rem;
        overflow: hidden;
        font-size: 0.75rem;
        background-color: #1b75bc;
        border-radius: 1.25rem;

        .progress-bar {
            justify-content: center;
            overflow: hidden;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            background-color: #f15a29;
            transition: width 0.6s ease;
        }
    }
    .delete-icon {
        width: 20px;
    }
    .btn {
        margin: 0 !important;
        &.delete {
            color: red;
        }
    }
}
