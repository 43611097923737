@import "styles/variables";

#home__container {
    padding-bottom: 30px;
    background-color: white;

    #home__front {
        background: url("../../assets/images/layer-8-copy.png") no-repeat;
        min-height: 50vh;
        background-size: cover;
        margin-bottom: 20px;
        position: relative;
        @media screen and (max-width: $md) {
            min-height: 30vh;
        }
        @media screen and (max-width: $sm) {
            min-height: 20vh;
        }

        #home__navigator {
            text-align: center;
            font-size: 1.6rem;
            position: absolute;
            top: 35%;
            padding-left: 10px;

            @media only screen and (max-width: $md) {
                max-width: 200px;
                font-size: 1rem;
                top: 30%;
                padding-left: 30px;

                h5 {
                    margin-bottom: 10px;
                }
            }
            @media only screen and (max-width: $sm) {
                font-size: 0.8rem;
                top: 20%;
            }
            @media screen and (min-width: $lg) {
                padding-left: 50px;
            }

            h5 {
                font-weight: bolder;
                font-size: inherit;
                color: white;
                font-style: italic;
                margin-bottom: 25px;
            }

            .know_more_button {
                border-radius: 25px;
                background: #fff;
                color: #000;

                @media screen and (max-width: 600px) {
                    padding: 0.5rem;
                    font-size: 0.7rem;
                }
            }
        }
    }

    .gray-text {
        margin-top: -10px;
        font-size: 1em;
    }

    .demo-btn {
        @media screen and (max-width: $md) {
            margin-top: 2rem;
        }

        button {
            padding: 5px 30px;
            background-color: #f15a29;
            font-weight: bold;
            font-size: 1em;
            margin-bottom: 20px;
        }
    }

    > h4 {
        margin: 15px 0;
        margin-top: 40px;
        color: #f15a29;
        font-size: 1.8em;
        font-weight: bolder;
    }

    .loan-types {
        min-width: 300px;
        flex-direction: column;

        .property {
            flex-direction: column;
            justify-content: space-between;
            border: 1px solid black;
            border-radius: 5px;
            overflow: hidden;
            width: 240px;
            height: 270px;

            img {
                width: 240px;
                height: 200px;
            }

            h5 {
                width: 70%;
                margin: auto;
                height: 100%;
                margin-top: 2px;
                font-weight: 500;
                padding: 15px 40px;
                cursor: pointer;
                font-size: 1.2em;
                border-top: 2px solid #1b75bc;
            }
        }

        > img {
            width: 280px;
            height: 20px;
        }
    }

    .properties-container {
        margin: 30px auto;
        margin-bottom: 55px;
        width: 850px;
        height: 270px;
        background: url("../../assets/images/back.png");
        background-size: cover;
        background-position: center;
        padding-bottom: 40px;

        .properties {
            width: 200px;
            flex-direction: column;

            img {
                height: 80px;
            }

            h5 {
                font-size: 0.8rem;
                text-align: center;
                width: 200px;
            }
        }
        @media screen and (max-width: 768px) {
            width: 190px;
            height: 600px;
            background: url("../../assets/images/back2.png") no-repeat;
            background-size: cover;

            .properties {
                transform: scale(0.8);
            }
        }
    }

    .gray-background {
        background: rgb(177 174 174 / 60.7%);
        padding: 26px;

        .reasons_grid_wrapper {
            @media screen and (max-width: 968px) {
                flex-direction: column;
                align-items: center;

                > div {
                    width: 100%;
                    max-width: 80%;
                }
            }
        }

        .friendly-image {
            img {
                width: 100%;
            }
        }

        .reasons {
            background-color: white;
            height: 100%;
            flex-direction: column;

            .why {
                h3 {
                    color: #f15a29;
                    font-size: 2em;
                    font-weight: bold;

                    img {
                        width: 200px;
                    }
                }
            }

            .reason {
                justify-content: space-evenly;
                width: 300px;
                margin: 10px 0;

                img {
                    width: 45px;
                }

                h6 {
                    margin: 0 10px;
                    color: #6d6e71;
                    margin-top: 10px;
                    font-weight: 600;
                    letter-spacing: 0;
                    font-size: 0.9rem;
                }
                @media screen and (max-width: 968px) {
                    width: 100%;
                }
            }
            @media screen and (max-width: 968px) {
                padding: 20px 0;
            }
        }
    }
}
