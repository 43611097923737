#styled-textfield {
    border: 1px solid black;
    padding-left: 14px;
}

#textfield {
    border: 1px solid black;
    border-radius: 10px;
}

.common-label {
    position: relative;
    @media screen and (max-width: 800px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 500px) {
        font-size: 10px !important;
    }
}
.view-file {
    font-size: 1rem;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    @media screen and (max-width: 800px) {
        font-size: 14px !important;
    }
    @media screen and (max-width: 500px) {
        font-size: 10px !important;
    }
    margin-left: 0.3rem;
}

.address-tab span {
    width: 100%;
}

.business-experience-tab .common__helper-text span {
    width: 100%;
}

.register-address-tab span {
    width: 100%;
}

.business-address-tab span {
    width: 100%;
}

.mailing-address-tab span {
    width: 100%;
}

.equipment-tab span {
    width: 100%;
}

.entity-tab span {
    width: 100%;
}

.coborrower-business-experience-tab span {
    width: 100%;
}

.guarantor-residential-address-tab span {
    width: 100%;
}

span {
    justify-self: center;
}

#tooltip {
    position: absolute;
    top: 0;

    img {
        width: 12px;
        height: 12px;
        margin-left: 1px;
        @media screen and (max-width: 800px) {
            width: 10px;
            height: 10px;
        }
        @media screen and (max-width: 500px) {
            width: 8px;
            height: 8px;
        }
    }
}

.MuiTooltip-tooltipArrow {
    color: white;
    background-color: #000;
    text-align: center;

    .MuiTooltip-arrow::before {
        background-color: #000;
    }
}
