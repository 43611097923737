@import "styles/variables";

#homePage__container {
    padding-bottom: 30px;
    background-color: white;
    padding-top: 2rem;
    height: 100%;
    min-width: 80vw;

    #card {
        border-radius: 10px;
        box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
        padding: 2rem;
        width: 80%;
        padding-left: 6rem;
        height: 80%;
        .progress-container {
            min-height: 70vh;
        }
        .item {
            padding-bottom: 5rem;
        }

        @media screen and (max-width: 1152px) {
            width: 80%;
            padding: 2rem;
        }

        @media screen and (max-width: 500px) {
            padding: 1rem;
            padding-left: 2rem;
        }

        #small__card {
            padding: 20px 10px;
            width: 80%;
            background-color: #e9ecef;
            border-radius: 10px;
            cursor: pointer;
            border: 1px solid black;
            min-height: 150px;
            height: 100%;

            @media screen and (max-width: 800px) {
                height: 100%;
            }

            .details {
                h5 {
                    padding-left: 1rem;

                    span {
                        color: #1b75bc;
                    }

                    padding-bottom: 5px;
                }
            }

            #btn {
                width: 50%;
                font-size: 1rem;
                margin-left: auto;

                @media screen and (max-width: 500px) {
                    font-size: 0.5rem;
                }
            }

            .delete-btn {
                color: red;
            }
        }
    }

    .loan-types {
        flex-direction: column;

        .property {
            flex-direction: column;
            justify-content: space-between;
            border: 1px solid black;
            overflow: hidden;

            img {
                width: auto;
                height: 120px;

                @media screen and (max-width: 500px) {
                    height: 100px;
                }
            }
        }
    }

    .progress {
        margin: 0.6rem 0;
        margin-left: 6px;
        width: 95%;
        height: 1rem;
        overflow: hidden;
        font-size: 0.75rem;
        background-color: #1b75bc;
        border-radius: 1.25rem;

        .progress-bar {
            justify-content: center;
            overflow: hidden;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            background-color: #f15a29;
            transition: width 0.6s ease;
        }

        #first {
            width: 9%;
        }

        #second {
            width: 45%;
        }

        #third {
            width: 36%;
        }
    }
}
