@import "styles/variables";

#login__container {
    width: 100%;
    min-height: 585px;
    background: #d7d7d7;

    // padding: 20px 0;

    #login__content {
        margin: 1.5rem auto;
        width: 90%;
        max-width: 1140px;

        #login__image {
            div {
                height: 100%;
                width: 100%;
                background-image: url("./../../assets/images/mask.png");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        #login__form {
            background: white;
            padding: 2rem 4rem;

            @media only screen and (max-width: $md) {
                padding: 2rem 4rem;
            }

            #login__heading {
                color: #f15a29;
                font-size: 2.25rem;
                text-transform: uppercase;
                font-weight: bolder;
                margin-bottom: 1rem;
            }

            #google-btn {
                background: rgb(219 60 35);
                width: 80%;
                padding: 0.7rem;
                color: #fff;
                border: 1px solid black;
                margin-bottom: 2rem;
            }

            #dash {
                border: 1px dashed #a39898;
                width: 68%;
                position: relative;
                margin-bottom: 2rem;

                #circle {
                    border-radius: 50%;
                    text-align: center;
                    border: 1px solid #e0e0e0;
                    font: 400 0.8rem "Open Sans", sans-serif;
                    text-transform: uppercase;
                    position: absolute;
                    left: 50%;
                    margin-left: -1rem;
                    top: -1rem;
                    padding: 0.4rem;
                    background-color: #fff;
                }
            }

            #login__btn {
                background-color: #f15a29;
                border: none;
                padding: 0.5rem 3rem;
                font-size: 1.3rem;
                color: #fff;
                border-radius: 0.3rem;
                text-transform: capitalize;
            }

            #forgot-password {
                color: #007bff;
                cursor: pointer;
                margin: 1rem 0;
                margin-right: 1rem;
                font-size: 1rem;
                text-align: right;
                width: calc(100% - 1rem);
            }

            #use-otp {
                color: #007bff;
                cursor: pointer;
                text-decoration: none;
                background-color: transparent;
                margin-left: 10px;
            }

            #register__btn {
                line-height: 3rem;
                font-size: 1rem;
                color: #007bff;
                cursor: pointer;
                text-decoration: none;
            }
        }
        .MuiOutlinedInput-notchedOutline{
            border: 0;
            outline: none;
        }

        .field {
            margin-bottom: 1rem;
            width: 100%;
             .helper-text{
              font-weight: 500;
             }
            .styled-textfield {
                border: 1px solid #2a2828;
                border-radius: 0.2rem;
                &.error {
                    border: 1px solid red;
                }
            }
        }
    }
}
