#construction-equipment__container {
    margin-top: 2rem;

    .loan-type {
        appearance: none;
        padding-right: 16px;
        background-image: url("./../../../../../../assets/images/expand_more.png");
        background-repeat: no-repeat;
        background-position: calc(100% + 21px) center;
        background-size: 100px;
        margin-bottom: 1rem;
        max-height: 2.2rem;
    }
}
