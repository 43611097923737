.contact-img {
    width: 98vw;
}

#contact-us-container {
    background-color: white;
    padding-bottom: 1.8rem;

    .contact-us-section-1 {
        position: relative;
    }

    .contact-text {
        position: absolute;
        top: 30%;
        left: 3%;
        color: white;
        font-size: 2.5rem;
        margin-left: -0.25rem;
        margin-top: 2.75rem;
        font-family: Arial;
        font-weight: 700;
        font-style: italic;
    }

    .contact-us-section-2 {
        width: 67vw;
        margin: auto;
        @media screen and (max-width: 800px) {
            width: 90vw;
        }
        @media screen and (max-width: 500px) {
            width: 95vw;

            .contact-us-section-2-item {
                text-align: center;
            }
        }

        .contact-us-section-2-h5 {
            font-size: 1.25rem;
            font-weight: 700;
            font-family: Arial;
            letter-spacing: 0.06rem;
            margin-top: 1.25rem;
        }

        .country-data {
            .country-data-h5 {
                width: 12.5rem;
                font-weight: 400;
                font-size: 1rem;
                margin-top: 0.25rem;
            }

            #email-field {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    #contact-us-container {
        .contact-us-section-2 {
            width: 90vw;

            .country-data {
                .country-data-h5 {
                    width: 10rem;
                    margin-top: 0.625rem;
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        .contact-text {
            top: 20%;
        }
    }
}

@media screen and (max-width: 500px) {
    #contact-us-container {
        .contact-us-section-2 {
            width: 95vw;

            .contact-us-section-2-item {
                text-align: center;
            }

            .country-data {
                .country-data-h5 {
                    margin: auto;
                    margin-top: 0.625rem;
                }
            }
        }

        .contact-text {
            top: 5%;
        }

        .contact-us-section-3 {
            width: 95vw;

            .left {
                text-align: center;
            }

            .right {
                padding-right: 0;

                .row1 {
                    .input-text {
                        padding: 0.31rem;
                        height: 0.625rem;
                    }

                    .row1-textfield {
                        width: 100% !important;
                        margin-top: 0.625rem;
                    }
                }

                .input-text-field {
                    padding: 0.31rem;
                    height: 0.625rem;
                }

                .button {
                    padding: 0.31rem;
                }
            }
        }
    }
}
