@import "../../styles/variables";

@font-face {
    font-family: zurich-cn-bt;
    src: url("./zurich-cn-bt.ttf") format("truetype");
}

#tabs-container {
    .MuiTabs-root .tab-item {
        // padding: 1rem 1rem 0.6rem 1rem;
        font-size: 17px !important;
        font-family: zurich-cn-bt, sans-serif;
        border-radius: 0.25rem;
        margin-left: 6px;
        min-width: 170px;
        height: 100%;
        margin-bottom: 5px;
        border: 1px solid #000;
        background-color: #f1f3f4;
        color: #666;

        &.blurred {
            background-color: #e2dfdf;
            opacity: 0.7;
        }

        &.Mui-selected {
            border: 1px solid #000;
            background-color: #fff;
            color: #1b75bc !important;
        }

        @media screen and (max-width: $sm) {
            width: 100px;
            min-width: none;
            font-size: 10px;
        }
    }

    .MuiTabs-indicator {
        display: none;
    }
}
