@import "styles/variables";

#requirement-form {
    margin-left: 2rem;
    width: 600px;
    position: relative;

    @media screen and (max-width: 700px) {
        margin-left: 0.5rem;
        width: 100%;
    }
}
