@import "styles/variables";

#profile__container {
    width: 100%;
    min-height: 585px;
    background: #d7d7d7;

    // padding: 20px 0;

    #profile__content {
        margin: 1.5rem auto;
        width: 90%;
        max-width: 1140px;

        #profile__form {
            background: white;
            padding: 2rem 4rem;

            @media only screen and (max-width: $md) {
                padding: 2rem 4rem;
            }

            #profile__heading {
                color: #f15a29;
                font-size: 2.25rem;
                text-transform: uppercase;
                font-weight: bolder;
                margin-bottom: 1rem;
            }

            #google-btn {
                background: rgb(219 60 35);
                width: 80%;
                padding: 0.7rem;
                color: #fff;
                border: 1px solid black;
                margin-bottom: 2rem;
            }

            #profile__btn {
                background-color: #f15a29;
                border: none;
                padding: 0.5rem 3rem;
                font-size: 1.3rem;
                color: #fff;
                border-radius: 0.3rem;
                text-transform: capitalize;
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border: 0;
            outline: none;
        }

        .field {
            margin-bottom: 1rem;
            width: 100%;
            .helper-text {
                font-weight: 500;
            }
            .styled-textfield {
                border: 1px solid #2a2828;
                border-radius: 0.2rem;
                &.error {
                    border: 1px solid red;
                }
            }
        }
    }
}
