#admin-view__container {
    // width: 95vw;
    padding: 10px 1rem;
    background-color: white;
    margin: auto;

    #thead {
        font-size: 1.2rem;
        font-weight: 700;
        @media screen and (max-width: 800px) {
            font-size: 0.8rem;
        }
    }
}
