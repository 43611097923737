.MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.accordion--common {
    box-shadow: none;
    border: 1px solid #303030;
    max-width: 100%;
    min-width: 100%;
    width: 560px;
    margin-bottom: 1rem !important;
    border-radius: 0.5rem !important;
    @media (max-width: 600px) {
        width: 100%;
    }
&.error {
    border: 1.5px solid red !important;
}


    .MuiAccordionSummary-root {
        min-height: 1rem;
    }

    .MuiAccordionSummary-content {
        margin: 5px 0;

        > div {
            margin: 0 !important;
        }
    }
}
