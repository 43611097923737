#small__card {
    padding: 20px 10px;
    width: 80%;
    background-color: #e9ecef;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid black;
    height: 150px;
    @media screen and (max-width: 800px) {
        width: 100%;
    }

    .details {
        h5 {
            padding-left: 1rem;

            span {
                color: #1b75bc;
            }

            padding-bottom: 5px;
        }
    }

    #btn {
        width: 100%;
        font-size: 1rem;
        margin-left: auto;
        @media screen and (max-width: 500px) {
            font-size: 0.5rem;
        }
    }

    .loan-types {
        flex-direction: column;

        .property {
            flex-direction: column;
            justify-content: space-between;
            border: 1px solid black;
            overflow: hidden;

            img {
                width: auto;
                height: 120px;
                @media screen and (max-width: 500px) {
                    height: 100px;
                }
            }
        }
    }
}
