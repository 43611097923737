@import "react-toastify/dist/ReactToastify.css";
@import "./variables";
@import "./common";

body,
html {
    padding: 0 !important;
    margin: 0 !important;
    overflow-x: hidden;
}
