#reset-password__container {
    width: 100%;
    height: 100%;
    .input-field {
        margin-bottom: 1rem;
    }
    .reset-btn {
        margin-top: 1rem;
        background-color: #f15a29;
        border: none;
        padding: 0.5rem 3rem;
        font-size: 1.3rem;
        color: #fff;
        border-radius: 0.3rem;
        text-transform: capitalize;
    }
}
