.btn-container {
    width: 640px;
    @media screen and (max-width: 700px) {
        width: 100%;
    }
    // min-width: 78vw;
    .btn-arrow {
        font-size: 1.2rem;
        color: #000;
        border: none;
        border-radius: 0.6rem;
        font-family: Arial;
        padding: 0.3rem 0.6rem;
        text-transform: capitalize;
        margin-bottom: 1rem;
        background-color: #dfcb93;
        margin-right: 7px;
        #right-arrow,
        #left-arrow {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0;
            margin-right: -8px;
            padding: 0;
        }
        #left-arrow {
            margin-right: 0;
            margin-left: -8px;
        }
        @media screen and (max-width: 800px) {
            font-size: 1rem;
            #right-arrow,
            #left-arrow {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
        @media screen and (max-width: 500px) {
            font-size: 0.7rem;
            #right-arrow,
            #left-arrow {
                width: 1rem;
                height: 1rem;
            }
        }
    }
}

.attach__container {
    position: relative;

    .close-icon {
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 20px;
        cursor: pointer;
    }
}

.attach {
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;

    span {
        vertical-align: top;
        font-size: 18px;
    }

    .year {
        font-size: 18px;
        margin-left: 8px;
    }
}
