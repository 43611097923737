$margin-between-fields: 1.2rem;

#short-form-container {
    padding: 2% 10%;
    background-color: white;

    #shadow-bottom {
        width: 100%;
        height: 2.5rem;
        opacity: 0.7;
        padding-top: 1rem;
        @media screen and (max-width: 800px) {
            width: 80%;
        }
        @media screen and (max-width: 500px) {
            width: 90%;
        }
    }
    @media screen and (max-width: 800px) {
        padding: 0;
        width: 80%;
        margin: auto;
    }
    @media screen and (max-width: 500px) {
        width: 90%;
    }

    #button-list-container {
        @media screen and (max-width: 800px) {
            padding-top: 1rem;
        }

        .button {
            border: 1px solid #a5a5a5;
            color: black;
            letter-spacing: 0;
            border-radius: 0.6rem 0 0 0.6rem;
            padding: 0.9rem 0.3rem;
            @media screen and (max-width: 800px) {
                padding: 0.6rem 2px;
                border-radius: 0.6rem 0.6rem 0 0;
                margin-right: 1px;
                width: 4rem;
            }
            @media screen and (max-width: 500px) {
                margin-right: 1px;
                padding: 5px 0;
            }

            &:nth-of-type(1) {
                background-color: #f9aaaa;
            }

            &:nth-of-type(2) {
                background-color: #f9e5aa;
            }

            &:nth-of-type(3) {
                background-color: #b9f9aa;
            }

            &:nth-of-type(4) {
                background-color: #aaf9f8;
            }

            &:nth-of-type(5) {
                background-color: #aabdf9;
            }

            &:nth-of-type(6) {
                background-color: #ffb5ec;
            }

            &.active {
                box-shadow: -2px 3px 4px grey;
                border-right: 0;
                z-index: 0;
            }

            .button-img {
                width: 60%;
                @media screen and (max-width: 500px) {
                    width: 45%;
                }
            }
        }
    }

    #form-container {
        height: 100%;
        border-radius: 0 10px 10px 0;
        filter: drop-shadow(rgb(35 31 32 / 30%) 0 1px 5px);

        #requirement-form {
            padding-left: 14px;
            background-color: #f9aaaa;
            border-radius: 0 10px 10px 0;

            .btn-arrow {
                background-color: #db9393;
            }
            @media screen and (max-width: 800px) {
                padding-top: 1rem;
            }
        }

        #entity-form {
            padding-left: 14px;
            background-color: #f9e5aa;
            border-radius: 0 10px 10px 0;

            .btn-arrow {
                background-color: #dfcb93;
            }
            @media screen and (max-width: 800px) {
                padding-top: 1rem;
            }
        }

        #experience-form {
            padding-left: 14px;
            background-color: #b9f9aa;
            border-radius: 0 10px 10px 0;

            .btn-arrow {
                background-color: #dfcb93;
            }
            @media screen and (max-width: 800px) {
                padding-top: 1rem;
            }
        }

        #loan-form {
            padding-left: 14px;
            background-color: #aaf9f8;
            border-radius: 0 10px 10px 0;

            .btn-arrow {
                background-color: #dfcb93;
            }
            @media screen and (max-width: 800px) {
                padding-top: 1rem;
            }
        }

        #offer-form {
            padding-left: 14px;
            background-color: #aabdf9;
            border-radius: 0 10px 10px 0;

            .btn-arrow {
                background-color: #dfcb93;
            }
            @media screen and (max-width: 800px) {
                padding-top: 1rem;
            }
        }

        #offers__images {
            img {
                width: auto;
                height: 40px;
                margin: 10px 5px;
                margin-top: 20px;
                @media screen and (max-width: 500px) {
                    height: 30px;
                }
            }
        }

        .short-form__title {
            font-size: 1.5rem;
            padding-top: 3.1rem;
            padding-left: 0.6re;
            color: #000;
            font-weight: 700;
            font-family: Arial;
            @media screen and (max-width: 800px) {
                font-size: 1.3rem;
                padding-top: 3rem;
            }
            @media screen and (max-width: 500px) {
                font-size: 1.5rem;
            }
        }

        .short-form__option-body {
            font-size: 1.1rem;
            color: #000;
            font-family: Arial;
            margin-top: 2.3rem;
            overflow-y: auto;
            height: 20rem;
            border-radius: 0;
            padding-left: 0.6rem;

            &::-webkit-scrollbar {
                width: 1rem;
                margin-right: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: #7c5454;
                border: 5px solid transparent;
                border-radius: 9px;
                background-clip: padding-box;
            }

            overflow-x: hidden;
            scrollbar-width: thin;

            @media screen and (max-width: 800px) {
                margin-top: 1.8rem;
                width: 95%;
                height: 13rem;
                padding-left: 0.6rem;
            }
            @media screen and (max-width: 500px) {
                font-size: 0.6rem;
            }

            .short-form__input {
                width: 70%;
                margin: 0.6rem 0;
                font-size: 1.5rem;
                font-weight: 700;
                background-color: white;
                font-family: Arial;
                @media screen and (max-width: 800px) {
                    width: 50%;
                    font-size: 0.6rem;
                }
                @media screen and (max-width: 500px) {
                    font-size: 0.3rem;
                    width: 70%;
                }
            }

            .button-submit {
                width: 70%;
                margin-top: 0.6rem;
                @media screen and (max-width: 800px) {
                    width: 50%;
                    font-size: 0.6rem;
                }
                @media screen and (max-width: 500px) {
                    font-size: 0.3rem;
                    width: 70%;
                }
            }

            .short-form__heading {
                margin-top: 0.5rem;
                font-size: 1.2rem;
                font-family: Arial;
                line-height: 1.5;
                margin-bottom: 0.3rem;
                font-weight: 600;
                @media screen and (max-width: 800px) {
                    font-size: 1rem;
                }
                @media screen and (max-width: 500px) {
                    font-size: 1.2rem;
                }
            }

            .short-form__input-field {
                height: 0.5rem;
                @media screen and (max-width: 800px) {
                    height: 0.3rem;
                }
                @media screen and (max-width: 500px) {
                    height: 0.1rem;
                }
            }

            .input-short {
                width: 50%;
                background-color: white;
                border-radius: 10px;
            }

            .subtitle {
                margin-top: 0.6rem;
                color: #000;
                width: 70%;
                font-style: italic;
            }

            #offers {
                font-size: 1.1rem;
                font-family: Arial;
                margin-top: 2.2rem;
                font-weight: 400;
                line-height: 1.5;
                @media screen and (max-width: 800px) {
                    font-size: 0.9rem;
                }
                @media screen and (max-width: 500px) {
                    font-size: 1.1rem;
                }

                b {
                    font-size: 1.2rem;
                    font-weight: bold;
                    @media screen and (max-width: 800px) {
                        font-size: 0.9rem;
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 1.1rem;
                    }
                }
            }

            .offer_text {
                margin-top: 2rem;
                font-size: 1em;
                margin-left: 4px;
                width: 80%;
                @media screen and (max-width: 800px) {
                    font-size: 0.8rem;
                }
                @media screen and (max-width: 500px) {
                    font-size: 0.7rem;
                }
            }
        }

        .progress {
            margin: 0.6rem 0;
            margin-left: 6px;
            width: 80%;
            height: 1rem;
            overflow: hidden;
            font-size: 0.75rem;
            background-color: #1b75bc;
            border-radius: 1.25rem;

            .progress-bar {
                justify-content: center;
                overflow: hidden;
                color: #fff;
                text-align: center;
                white-space: nowrap;
                background-color: #f15a29;
                transition: width 0.6s ease;
            }

            #requirement {
                width: 20%;
            }

            #entity {
                width: 40%;
            }

            #experience {
                width: 60%;
            }

            #loan {
                width: 80%;
            }

            #offer {
                width: 100%;
            }
        }

        .btn-arrow {
            font-size: 1.3rem;
            color: #000;
            border: none;
            border-radius: 0.6rem;
            font-family: Arial;
            padding: 0.3rem;
            text-transform: capitalize;
            margin-bottom: 1rem;
            margin-right: 7px;
            @media screen and (max-width: 800px) {
                font-size: 1rem;
            }
            @media screen and (max-width: 500px) {
                font-size: 0.8rem;
            }
        }
    }
}
