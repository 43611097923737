.header-container {
    img {
        max-width: 200px;
        @media screen and (max-width: 768px) {
            max-width: 150px;
        }
    }

    nav {
        width: 100%;
    }

    .nav-list {
        button {
            background: transparent !important;
            font-size: 1.3em;
        }
    }

    .slider-btn {
        // button{
        background: transparent !important;

        // }
    }

    button {
        background: #f15a29 !important;
    }
}
