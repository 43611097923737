.table__container {
    max-width: 80%;
    margin: 20px auto;
    min-height: 75vh;

    th {
        border: 1px solid black;
        font-weight: 600;
    }

    td {
        border: 1px solid black;
    }
}
