@import "styles/variables";

footer {
    width: 100%;
    background: white;

    .footer__container {
        padding: 10px;

        h6 {
            padding-bottom: 0.6rem;
        }

        .links {
            img {
                width: 40px;
                margin: 0 10px;
                @media screen and (max-width: $md) {
                    width: 25px;
                }
            }
        }
    }

    .footer_bottom_colorBox {
        width: 100%;
        height: 12px;
        background: #1b75bc;
    }
}
