@import "styles/variables";

#form {
    // margin: 0;
    // padding-bottom: 100px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.dropdown-textfield {
    width: 65%;
    border: 1px solid black;
    border-radius: 10px !important;
}

#entity-form {
    margin-top: 1.5rem;
    @media screen and (max-width: $sm) {
        margin-left: 0;
    }

    .entity-container {
        width: 100%;
        max-width: 45vw;
        min-width: 35vw;
    }

    #business-container {
        margin-bottom: 0.6rem;
    }

    fieldset {
        border-radius: 0.6rem;
    }

    #kind-of-experience {
        border: 1px solid black;
    }
}

.form-wrapper {
    margin-top: 1.5rem;

    @media screen and (max-width: $sm) {
        margin-left: 0;
    }

    fieldset {
        border-radius: 0.6rem;
    }
}

@media screen and (max-width: 800px) {
    .field-text {
        position: relative;
        font-size: 14px !important;
    }
}

@media screen and (max-width: 500px) {
    .field-text {
        font-size: 10px !important;
    }
}
